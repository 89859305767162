@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets';

// http://www.colourlovers.com/palette/950206/Fireflower
$colour-playerhater: #580000; // Playerhater
$colour-seajewel: #035C6A; // Sea Jewel
$colour-mymotherscolor: #447C55; // My Mother's Color
$colour-alwayssunny: #F5D254; // Always Sunny
$colour-rougesombre: #A20000; // rouge sombre

$brand-primary: $colour-playerhater;
$brand-success: $colour-mymotherscolor;
$brand-info: $colour-seajewel;
$brand-warning: $colour-alwayssunny;
$brand-danger: $colour-rougesombre;

$jumbotron-hero-from-colour: $brand-primary;
$jumbotron-hero-to-colour: #fff;

$navbar-default-brand-color: $brand-primary;
$navbar-default-bg: rgba(255, 255, 255, 0.8);

$navbar-default-link-color: lighten($brand-primary, 10%);
$navbar-default-link-active-bg: rgba($brand-primary, 0.1); // Playerhater but opacity 10%

@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';


/*
//=== Inverted navbar
$navbar-inverse-bg:                         $brand-primary;
$navbar-inverse-link-hover-bg:              darken($brand-primary, 3%);
$navbar-inverse-link-active-bg:             lighten($brand-primary, 10%);
$navbar-inverse-toggle-hover-bg:            $navbar-inverse-link-active-bg;
$navbar-inverse-toggle-icon-bar-bg:         $navbar-inverse-link-color;
$navbar-inverse-toggle-border-color:        $navbar-inverse-link-color;

//$navbar-inverse-link-color : darken($brand-warning, 25%);
*/

@import './src/styles/vendor/bootstrap-custom';

//@import './src/styles/components/btn-breadcrumb';
@import './src/styles/components/sticky-footer';
//@import './src/styles/components/fullscreen-background';
@import './src/styles/components/alerts';
@import './src/styles/components/loader';
@import './src/styles/components/jumbotron';

.bing {
	h1 {
		color: #fff;
	}

	.page-header {
		border-bottom: none;
	}
}
